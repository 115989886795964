import React, { useEffect, useState } from 'react';
import { Header, Image } from 'react-frontier';
import { EventCatalogResponse } from '@arema/components/Classes';
import { CDN_URL } from '../PublicConfig';
import { cropString } from '@arema/components/Util';
import { firstBy } from 'thenby';
import { EventItem } from '../components';
import moment from 'moment';
import API from '../PublicAPI';

const PLACEHOLDER_AMOUNT = 12;

var EventCatalog = ()=>{
	var [data, setData] = useState<EventCatalogResponse>(null);
	var [animTimer, setAnimTimer] = useState<number>(0);
	var [loadError, setLoadError] = useState<string>(null);

	useEffect(()=>{
		var intvl : NodeJS.Timer = null;
		var timer = 0;
		if(!data){
			API.getEventCatalog().then(res=>{
				if(res.error) return setLoadError(res.message);
				setData(res.data);
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando el catálogo de eventos (LCL-1)');
			})
			intvl = setInterval(()=>{
				timer++;
				if(timer>=PLACEHOLDER_AMOUNT) timer = 0;
				setAnimTimer(timer);
			}, 150);
		}

		return ()=>{
			if(intvl) clearInterval(intvl);
		}
	}, [data]);
	
	if(loadError){
		return <Header
			containerStyle={{ marginTop: 20 }}
			text='Error'
			iconName='face-frown-open'
			subtext={loadError}
		/>
	}

	var EVENT_CARDS : { date: number, element: JSX.Element }[] = [];

	if(data?.events){
		for(let i of data.events){
			EVENT_CARDS.push({
				date: i.date,
				element: <EventItem
					key={`EVT-${i.event_id}`}
					name={i.event_name}
					url={`/${i.subdomain ? '@' : `e/`}${i.subdomain || i.event_id}`}
					headerLeft={i.category_name}
					headerRight={moment.unix(i.date).format('DD/MMM')}
					poster={i.poster || `${CDN_URL}/events/${i.event_id}/400.webp`}
					meta={[
						cropString(i.venue_name || '', 32, true),
						cropString(`${i.city}${i.state && i.state.length>0 ? ', ' : ''}${i.state && i.state.length>0 ? i.state : ''}`, 32, true)
					]}
				/>
			});
		}
	}
	if(data?.legacy_events){
		for(let i of data.legacy_events){
			EVENT_CARDS.push({
				date: i.primera_fecha,
				element: <EventItem
					key={`EVTL-${i.idevento}`}
					name={i.evento_nombre}
					url={`/el/${i.idevento}`}
					headerLeft={i.categoria}
					headerRight={moment.unix(i.primera_fecha).format('DD/MMM')}
					poster={i.poster}
					meta={[
						cropString(i.recinto || '', 32, true),
						cropString(i.ciudad, 32, true)
					]}
				/>
			})
		}
	}
	if(data?.groups){
		for(let i of data.groups){
			EVENT_CARDS.push({
				date: i.date,
				element: <EventItem
					key={`EVG-${i.group_id}`}
					name={i.group_name}
					url={`/${i.subdomain ? '@' : `g/`}${i.subdomain || i.group_id}`}
					headerLeft={i.category_name}
					headerRight={moment.unix(i.date).format('DD/MMM')}
					poster={i.poster || `${CDN_URL}/groups/${i.group_id}/400.webp`}
					meta={[
						`${i.events} evento${i.events==1 ? '' : 's'}`
					]}
				/>
			})
		}
	}
	if(data?.legacy_groups){
		for(let i of data.legacy_groups){
			EVENT_CARDS.push({
				date: i.primera_fecha,
				element: <EventItem
					key={`EVGL-${i.idpadre}`}
					name={i.nombre}
					url={`/${i.subdominio ? '@' : `gl/`}${i.subdominio || i.idpadre}`}
					headerLeft={i.categoria}
					headerRight={moment.unix(i.primera_fecha).format('DD/MMM')}
					poster={i.poster}
					meta={[
						i.eventos && i.eventos>0 ? `${i.eventos} evento${i.eventos==1 ? '' : 's'}` : null
					]}
				/>
			})
		}
	}


	return <div className="ar events">
		{data && EVENT_CARDS ? EVENT_CARDS.sort(firstBy('date', 'asc')).map(a=>((
			a.element
		))) : (
			(new Array(PLACEHOLDER_AMOUNT).fill('arema')).map((a,i)=>(
				<EventItem placeholder key={`evphldr-${i}`} />
			))
		)}
	</div>
}

export default EventCatalog;