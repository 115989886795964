import React, { useEffect, useState } from 'react';
import { Header } from 'react-frontier';
import { useTranslation } from 'react-i18next';

var NotFound = ()=>{
	var { t } = useTranslation();
	return <Header
		text={t('notfound.code')}
		subtext={t('notfound.message')}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 20 }}
	/>
}

export default NotFound;