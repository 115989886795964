import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useRouteError, Outlet } from 'react-router-dom';
import { TitleProvider } from '@arema/components/Hooks'
import { Navbar } from './components';
import { SitePrivacy } from '@arema/components';
import { Header } from 'react-frontier';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Locale from '@arema/components/src/locale'

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';
import './style/public.scss'

import {
	CreateInvoice,
	EventCatalog, 
	Mytickets,
	Nosotros,
	PublicEvent,
	PublicEventGroup,
	PublicRefund,
	PublicSubdomain,
	Refund,
	RefundForm,
	RefundsInfo,
} from './screens'


i18n.use(initReactI18next).use(Locale('public')).init({
	fallbackLng: 'es',
	interpolation: {
		escapeValue: false,
	}
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = ()=>{
	const error = (useRouteError() as any);
	return <Header
		text={error.status}
		subtext={error.status===404 ? 'Página no encontrada' : `Hubo un error inesperado. (PBL-LCL-${error.status})`}
		style={{ fontSize: 100 }}
		subheaderStyle={{ fontSize: 20 }}
		containerStyle={{ marginTop: 20 }}
	/>
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	return <div style={{ paddingBottom: 50 }}>
		<TitleProvider suffix='AREMA Ticket'>
			<Navbar />
			<div style={{ paddingTop: 60 }}>
				{props.outlet}
			</div>
		</TitleProvider>
	</div>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <SiteContainer outlet={<ErrorElement />} />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		// == Events ==
		{ path: '/', element: <EventCatalog /> },
		{ path: '/e/:event', element: <PublicEvent /> },
		{ path: '/el/:event', element: <PublicEvent legacy /> },

		// == Groups ==
		{ path: '/g/:group', element: <PublicEventGroup /> },
		{ path: '/gl/:group', element: <PublicEventGroup legacy /> },

		// == Refunds ==
		{ path: '/reembolsos', element: <RefundsInfo /> },
		{ path: '/reembolsar', element: <Refund /> },
		{ path: '/reembolsar/:token', element: <RefundForm /> },
		{ path: '/reembolsar/:token/request', element: <RefundForm request /> },
		{ path: '/reembolso/:token', element: <PublicRefund /> },
		
		// == Invoice ==
		{ path: '/facturar', element: <CreateInvoice /> },

		// == Misc ==
		{ path: '/nosotros', element: <Nosotros /> },
		{ path: '/miscompras', element: <Mytickets /> },
		{ path: '/privacidad', element: <SitePrivacy /> },

		// == Subdomain ==
		{ path: '/:subdomain', element: <PublicSubdomain /> },
	]
}]);

var Root = ()=>{
	return <RouterProvider router={Router} />
}

root.render(<Root />);