import React, { useEffect, useState } from 'react';
import { Button, Field, Groupper, Header, Image, Input, Message } from 'react-frontier';
import { useNavigate, useParams } from 'react-router-dom';
import { addCommas, bindFormChange, formatCreditCard, getBankParticipants, getRefundValidator, isJWT, validateClabeParticipant } from '@arema/components/Util';
import { BankTransferData, DepositType, SetLoading } from '@arema/components/Classes'
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { clabe } from 'clabe-validator';
import { TicketItems } from '../components';
import { useTitle } from '@arema/components/Hooks';
import Validator from '@arema/components/Validator';
import API from '../PublicAPI';
import moment from 'moment';

import '@arema/components/style/tickets.scss'

interface RefundableData{
	refund_id?: number,
	order_hash: string,
	other_cost: number,
	date_paid: number,
	ask_info: boolean,
	ask_contact: boolean,
	payments: {
		payment_id: number,
		payment_method: number,
		method_name: string,
		date_paid: number,
		direct_refund: boolean,
	}[],
	tickets: {
		ticket_hash: string,
		event_id: number,
		event_name: string,
		date_id: number,
		date: number,
		ticket_cost: number,
		section_id: number,
		section_name: string,
		price_id: number,
		price_name: string,
		numbered: boolean,
		seat_number: string,
		seat_row: string,
		seat_section: string,
	}[],
}

var RefundForm = (props: { request?: boolean })=>{
	var params = useParams();
	var navigate = useNavigate();
	var { setTitle } = useTitle();
	var [data, setData] = useState<RefundableData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [refundData, setRefundData] = useState<BankTransferData & { terms: boolean }>({
		email: '',
		type: null,
		account_owner: '',
		clabe: '',
		card_number: '',
		bank: '',
		terms: false,
	});

	useEffect(()=>{
		if(!data && params.token && isJWT(params.token)){
			API.getRefundableData(params.token, !!props.request).then(res=>{
				if(res.error) return setLoadError(res.message);
				setRefundData({
					email: res.data.email || '',
					type: null,
					account_owner: '',
					clabe: '',
					card_number: '',
					bank: '',
					terms: false,
				})
				setData(res.data);
				setTitle(`Reembolsar ${res.data.order_hash}`);
			}).catch(err=>{
				return setLoadError('Hubo un error inesperado cargando la información de reembolso (LCL-1)');
			});
		}
	}, []);

	if(loadError){
		return <Header text='Error' subtext={loadError} iconName='exclamation-circle' style={{ marginTop: 20 }} />
	}

	if(!data){
		return <Header loading text='Cargando datos...' />
	}

	var submit = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(refundData, {
			email: data.ask_contact ? [{ rule: 'email', prompt: 'El correo electrónico no es válido' }] : [],
			terms: [{ rule: 'empty', prompt: 'Favor de aceptar los términos de el reembolso.' }],
			...(data.ask_info ? getRefundValidator(refundData.type) : {}),
		});

		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.finishRefund(params.token, refundData, props.request).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data?.token){
				return navigate(`/reembolso/${res.data.token}`);
				// setErrorPrompts([`CREATED WITH ID: ${res.data?.refund_id}`]);
			}else{
				setErrorPrompts(['Hubo un error inesperado creando el reembolso. (LCL-2)']);
			}
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado creando el reembolso. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var tickets_total = 0;
	for(var i of data.tickets){
		tickets_total += Math.max(i.ticket_cost, 0);
	}

	var not_direct = false;
	var different_deposits : RefundableData["payments"] = [];
	for(var p of data.payments){
		if(p.direct_refund || (!not_direct && !p.direct_refund)){
			different_deposits.push(p);
		}
	}

	var onDataChange = bindFormChange(refundData, setRefundData);
	
	return <div>
		<Groupper className="tickets" title='Boletos' width={500} style={{ marginTop: 15, paddingBottom: 0 }} titleCentered>
			{data.tickets.length>0 ? (
				<TicketItems tickets={data.tickets} />
			) : (
				<Header text='Sin boletos' subtext='Este reembolso no tiene boletos' style={{ marginBottom: 25 }} />
			)}
			<table className="fr striped divided last totals table" style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}>
				<tbody>
					<tr>
						<td>Fecha compra</td>
						<td className="collapsing">{moment.unix(data.date_paid).format('DD/MMM/YY HH:mm')}</td>
					</tr>
					<tr>
						<td>Boletos</td>
						<td className="collapsing">${addCommas(tickets_total)}</td>
					</tr>
					<tr>
						<td>Otros cargos</td>
						<td className="collapsing">${addCommas(data.other_cost)}</td>
					</tr>
					<tr className="totals">
						<td>Total a reembolsar</td>
						<td className="collapsing" style={{ color: 'brown' }}>${addCommas(tickets_total+data.other_cost)}</td>
					</tr>
				</tbody>
			</table>
		</Groupper>
		{different_deposits.length>=2 && (
			<Groupper title='Formas de pago' width={500} style={{ marginTop: 15 }}>
				<Message text={`Ya que el pago se realizó en ${data.payments.length} pagos, se necesitarán hacer ${different_deposits.length} reembolsos.`} style={{ marginBottom: 40 }} />
				<table className="fr table fitted">
					<thead>
						<tr>
							<th>Reembolso</th>
							<th>Fecha de pago</th>
						</tr>
					</thead>
					<tbody>
						{different_deposits.map(a=>(
							<tr key={`rfpm-${a.payment_id}`}>
								<td>{!a.direct_refund ? 'Transferencia' : a.method_name}</td>
								<td className='collapsing'>{moment.unix(a.date_paid).format('DD/MM/YY HH:mm')}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Groupper>
		)}
		<Groupper title='Datos de reembolso' style={{ marginTop: 15 }} width={500} titleCentered actions={(
			<Button text='Enviar' color='black' onClick={submit} />
		)}>
			<Input label='Folio de compra' value={data.order_hash} readonly />
			<Input label='Correo electrónico' value={refundData.email} onChange={onDataChange('email')} readonly={!data.ask_contact} comment={data.ask_contact ? null : 'Ya que la compra fue por internet, no se puede modificar el correo electrónico.'} />
			{!data.ask_info ? (
				<Message type='info' style={{ marginBottom: 10 }} list={[
					'La cantidad a reembolsar será reembolsada directamente a la tarjeta bancaria con la cual se realizó la compra.',
					'Los bancos pueden tomar algunos días en reflejar este tipo de movimientos en su estado de cuenta y saldo, por lo que le sugerimos estar al pendiente de ello.'
				]} />
			) : <>
				<Field label='Tipo de transferencia'>
					<Dropdown selection placeholder='Tipo de transferencia' value={refundData.type} onChange={onDataChange('type', true)} options={[
						{ text: 'Transferencia bancaria (SPEI)', value: DepositType.SPEI },
						{ text: 'Deposito a tarjeta bancaria', value: DepositType.CARD }
					]} />
				</Field>
				{refundData.type===DepositType.SPEI ? (
					<>
						<Input label='Propietario de cuenta' value={refundData.account_owner} onChange={onDataChange('account_owner')} />
						<Input label='CLABE' value={refundData.clabe} onChange={onDataChange('clabe')} />
						<Input label='Banco' readonly value={clabe.validate(refundData.clabe).bank} comment='Este campo se llena automáticamente' />
					</>
				) : refundData.type===DepositType.CARD ? (
					<>
						<Input label='Nombre en la tarjeta' value={refundData.account_owner} onChange={onDataChange('account_owner')} />
						<Input label="Número de la tarjeta" value={refundData.card_number} onChange={onDataChange('card_number')} valueFormat={formatCreditCard} maxLength={16+3} />
						<Field label='Banco'>
							<Dropdown search selection selectOnBlur={false} value={refundData.bank} onChange={onDataChange('bank', true)} placeholder='Banco de la tarjeta' options={getBankParticipants().map((a,i)=>({
								text: a.bank_name,
								value: a.participant,
								key: `${a.participant}-${i}`
							}))} />
						</Field>
						<Message type='info' style={{ marginBottom: 10, marginTop: 15 }}>
							<ul>
								<li>Para evitar cualquier tipo de problema, <b>favor de ingresar solo tarjetas de débito</b>.</li>
								<li>Por el momento solo podemos hacer reembolsos a <b>tarjetas de instituciones bancarias mexicanas</b>.</li>
							</ul>
						</Message>
					</>
				) : null}
			</>}
			{refundData.type || !data.ask_info ? (
				<Checkbox 
					checked={refundData.terms} 
					onChange={onDataChange('terms', true)} 
					style={{ marginTop: 10 }}
					label={(
						<label>
							Acepto que al enviar este formulario, los boletos a los cuales se les solicitó el reembolso 
							<span style={{ color: 'brown', fontWeight: 'bold', marginLeft: 3, marginRight: 3 }}>serán cancelados</span> 
							y no podrán ser recuperados.
						</label>
					)} 
				/>
			) : null}
			<Message style={{ marginTop: 15 }} type='error' list={errorPrompts} />
		</Groupper>
	</div>
}

export default RefundForm;