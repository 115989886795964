import React, { useEffect, useRef, useState } from 'react';
import { Button, Groupper, Header, Input, Message } from 'react-frontier';
import { useTitle } from '@arema/components/Hooks';
import { Modal } from 'semantic-ui-react';
import { bindChange, bindClose, bindFormChange } from '@arema/components/Util';
import { CAPTCHA_ACTIVE, RECAPTCHA } from '../PublicConfig';
import { Link } from 'react-router-dom';
import { SetLoading } from '@arema/components/Classes';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import Validator from '@arema/components/Validator';
import API from '../PublicAPI';

import '../style/mytickets.scss';

interface SearchForm{
	order_hash: string,
	email: string,
}

var Mytickets = ()=>{
	var { t } = useTranslation();
	var { setTitle } = useTitle();
	var captchaRef = useRef<ReCAPTCHA>(null);
	var [searchModal, setSearchModal] = useState<boolean>(null);
	var [captcha, setCaptcha] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [ticketUrl, setTicketUrl] = useState<string>(null);
	var [searchForm, setSearchForm] = useState<SearchForm>({
		order_hash: '',
		email: '',
	});

	useEffect(()=>{
		setTitle(t('tickets.title'));
	}, []);

	var showSearchModal = ()=>{
		setTicketUrl(null);
		setErrorPrompts(null);
		setSearchForm({
			order_hash: '',
			email: '',
		})
		setSearchModal(true);
	}

	var searchTickets = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(searchForm, {
			order_hash: [
				{ rule: /[0-9a-z]{4}/gi, prompt: t('tickets.form.order_hash') }
			],
			email: [
				{ rule: 'email', prompt: t('tickets.form.email') }
			]
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.searchTickets(searchForm.order_hash, searchForm.email, captcha).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data.tickets_url){
				setTicketUrl(res.data.tickets_url);
			}else{
				setErrorPrompts([t('unexpected_error', { type: 'buscando los boletos', code: 'LCL-2' })]);
			}
		}).catch(err=>{
			setErrorPrompts([t('unexpected_error', { type: 'buscando los boletos', code: 'LCL-1' })]);
		}).finally(()=>{
			setLoading(false);
			captchaRef.current?.reset();
		});
	}

	var onFormChange = bindFormChange(searchForm, setSearchForm);
	
	return <div className="fr mytickets-screen">
		<Header text={t('tickets.title')} style={{ marginTop: 15 }} />
		<Groupper width={500} title={t('tickets.recover.title')} titleCentered>
			<p>{t('tickets.recover.text')}</p>
			<Button color='orange' text={t('tickets.recover.button')} size='big' iconName='file-pdf' onClick={showSearchModal} />
		</Groupper>
		<Groupper width={500} title={t('tickets.invoice.title')} titleCentered>
			<p>{t('tickets.invoice.text')}</p>
			<Button color='orange' text={t('tickets.invoice.button')} size='big' iconName='file-text' as={Link} to={'/facturar'} />
		</Groupper>
		
		<Modal size='tiny' open={searchModal} onClose={bindClose(setSearchModal)}>
			<Modal.Header>{t('tickets.recover_modal.header')}</Modal.Header>
			<Modal.Content>
				<Input label={t('tickets.recover_modal.form.order_hash')} value={searchForm.order_hash} onChange={onFormChange('order_hash')} />
				<Input label={t('tickets.recover_modal.form.email')} comment={t('tickets.recover_modal.form.email_comment')} onChange={onFormChange('email')} />
				{!!CAPTCHA_ACTIVE && (
					<ReCAPTCHA 
						ref={captchaRef}
						hl='es-419'
						sitekey={RECAPTCHA}
						onChange={setCaptcha}
						onExpired={()=>setCaptcha(null)}
					/>
				)}
				{!!ticketUrl && (
					<Message type='info' centered>
						<div className="header">{t('tickets.recover_modal.download.header')}</div>
						{t('tickets.recover_modal.download.text')}
						<br />
						<Button color='blue' style={{ marginTop: 10, width: 200 }} text={t('tickets.recover_modal.download.button')} iconName='file-pdf' as={Link} to={ticketUrl} target={'_blank'} />
					</Message>
				)}
				<Message list={errorPrompts} type='error' />
			</Modal.Content>
			<Modal.Actions>
				<Button text={t('close')} basic onClick={bindClose(setSearchModal)} />
				<Button text={t('search')} color='black' onClick={searchTickets} disabled={!!ticketUrl} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default Mytickets;