import React, { useEffect, useState } from 'react';
import { Accordion, Button, Groupper, Header, Message } from 'react-frontier';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NotFound } from '../components';
import { useTitle } from '@arema/components/Hooks';

interface QuestionContent{
	title: string,
	content: string[]
}

var RefundsInfo = ()=>{
	var { t } = useTranslation()
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle(t('refunds.title'));
	}, []);

	var questions : QuestionContent[] = (t('refunds.questions', { returnObjects: true }) as any);
	if(!questions || !questions.length) return <NotFound />;

	return <div>
		<Header text={t('refunds.title')} style={{ marginTop: 15 }} />
		<Message centered style={{ maxWidth: 600, margin: 'auto' }}>
			{t('refunds.info_message')}
		</Message>

		<Header text={t('refunds.request_refund')} style={{ marginTop: 30 }} subtext={t('refunds.request_refund_detail')} />
		<Button text={t('refunds.create_refund')} color='orange' style={{ display: 'block', width: 340, margin: 'auto', fontSize: 20, padding: '15px 30px' }} as={Link} to={'/reembolsar'} />

		<Header text={t('refunds.frequent_questions')} style={{ marginTop: 30, marginBottom: 20 }} />
		<Groupper fitted style={{ overflow: 'hidden' }} width={800}>
			<Accordion divided selectable>
				{questions.map(a=>(
					<Accordion.Item title={a.title} bold>
						{a.content.map(b=>(
							<p>{b}</p>
						)) as any}
					</Accordion.Item>
				))}
			</Accordion>
		</Groupper>
	</div>
}

export default RefundsInfo;