import React, { useEffect, useRef, useState } from 'react';
import { CDN_ROOT, CDN_URL } from '../PublicConfig';
import { Link, useLocation } from 'react-router-dom';
import { Icon, TransitionGroup } from 'semantic-ui-react';
import { Toaster } from 'react-hot-toast';
import { Button, Header, Image, Input, Placeholder } from 'react-frontier';
import { PLACEHOLDER_IMG } from '../PublicConfig';
import { cropString, randomRange } from '@arema/components/Util';
import { SearchEvent as SearchEventClass } from '@arema/components/Classes';
import { useTranslation } from 'react-i18next';
import API from '../PublicAPI';
import classNames from 'classnames';

interface SearchEventProps{
	placeholder?: boolean,
	event?: SearchEventClass
}

var SearchEvent = (props: SearchEventProps)=>{
	if(props.placeholder){
		return <div className="ar search-event placeholder">
			<div className="image placeholder"></div>
			<div className="data">
				<Placeholder height={20} width={168} />
				<Placeholder height={16} width={57} />
				<Placeholder height={16} width={78} />
			</div>
		</div>
	}
	if(!props.event) return null;
	return <Link className="ar search-event" to={`/e${props.event.legacy ? 'l' : ''}/${props.event.event_id}`} replace>
		<Image src={props.event.poster ? props.event.poster : `${CDN_URL}/events/${props.event.event_id}/800.webp`} fallback={PLACEHOLDER_IMG} />
		<div className="data">
			<div className="title">{props.event.event_name}</div>
			<div className="meta">{cropString(props.event.venue_name, 64)}</div>
			<div className="meta">{cropString(`${props.event.city}${props.event.state && props.event.state.length>0 ? ', ' : ''}${props.event.state && props.event.state.length>0 ? props.event.state : ''}`, 64, true)}</div>
		</div>
	</Link>
}

interface TrendProps{
	placeholder?: boolean,
	text?: string,
	onClick?: (trend: string)=>void,
}

var Trend = (props: TrendProps)=>{
	var [random] = useState<number>(randomRange(0, 20));

	if(props.placeholder){
		return <div className="trend placeholder" style={{ width: 80+random }}>
			<div className="pill"></div>
		</div>
	}
	return <div className="trend" onClick={()=>{
		if(props.onClick) props.onClick(props.text)
	}}>{props.text}</div>
}

interface Webdata{
	trends: string[],
	bestsellers: SearchEventClass[]
}

var Navbar = ()=>{
	var searchRef = useRef<HTMLInputElement>(null);
	var { t } = useTranslation();
	var [sidebar, setSidebar] = useState<boolean>(false);
	var [searchMode, setSearchMode] = useState<boolean>(false);
	var [webdata, setWebdata] = useState<Webdata>(null);
	var [searchTimeout, setSearchTimeout] = useState(null);
	var [searchQuery, setSearchQuery] = useState<string>('');
	var [searching, setSearching] = useState<boolean>(false);
	var [searchResults, setSearchResults] = useState<{ query: string, results: SearchEventClass[] }>(null);
	var [searchError, setSearchError] = useState<string>(null);
	var location = useLocation();

	const NAVBAR_ITEMS = [
		{ text: t('navbar.events'), href: '/' },
		{ text: t('navbar.about'), href: '/nosotros' },
		{ text: t('navbar.orders'), href: '/miscompras' },
	]

	useEffect(()=>{
		setSidebar(false);
		setSearchMode(false);
	}, [location]);

	useEffect(()=>{
		if(searchMode && !webdata){
			API.getPopular(true).then(res=>{
				if(res.error) return;
				setWebdata(res.data);
			}).catch(err=>{});
		}
	}, [searchMode, webdata]);

	var searchEvent = (query: string)=>{
		setSearching(true);
		API.searchEvents(query).then(res=>{
			if(res.error) return setSearchError(res.message);
			setSearchError(null);
			setSearchResults({
				query: query,
				results: res.data.events
			})
		}).catch(err=>{
			setSearchError(t('unexpected_error', { type: 'buscando eventos', code: 'LCL-1' }));
		}).finally(()=>{
			setSearching(false);
		})
	}

	var onQuery = (query: string)=>{
		setSearchQuery(query);
		if(searchTimeout) clearTimeout(searchTimeout);
		setSearchError(null);
		if(!query || query.length<3 || (searchResults && searchResults.query.trim().toLowerCase()===query.trim().toLowerCase())){
			setSearching(false);
			return;
		}

		setSearching(true);
		setSearchTimeout(setTimeout(()=>{
			searchEvent(query);
		}, 1000));
	}

	var startSearching = ()=>{
		setSearchMode(true);
		setTimeout(()=>{
			searchRef.current?.focus();
		}, 200);
	}

	var results_left = [], results_right = [];
	if(searchResults){
		for(var c=0; c<Math.min(6, searchResults.results.length); c++){
			if(c<3) results_left.push(searchResults.results[c])
			else results_right.push(searchResults.results[c])
		}
	}
	
	return <>
		<div className={classNames("ar navbar", { search: searchMode })}>
			<div className="left">
				<div className="hamburger item" onClick={()=>setSidebar(!sidebar)}>
					<Icon name={!sidebar ? 'bars' : 'remove'} />
				</div>
				<Link to="/" className="logo item">
					<img src={`${CDN_ROOT}/assets/logo/LogoH_Large.webp`} className='large' alt="AREMA" />
					<img src={`${CDN_ROOT}/assets/logo/Logo_Small.webp`} className='icon' alt="AREMA" />
				</Link>
			</div>
			<div className="center">
				<Input ref={searchRef} value={searchMode ? searchQuery : ''} onChange={onQuery} icon='search' placeholder={t('navbar.search')} onFocus={startSearching} />
				<div className="item icon" onClick={startSearching}>
					<Icon name="search" />
				</div>
			</div>
			<div className="right">
				<TransitionGroup animation='fade up' duration={300}>
					<div className="show-search item" onClick={startSearching}>
						<Icon name='search' />
						{t('search')}
					</div>
					{!searchMode ? (
						NAVBAR_ITEMS.map((a, i)=>(<Link className="item" to={a.href} key={`navbar-itm-${i}`}>{a.text}</Link>))
					) : (
						<div className="item icon search" onClick={()=>setSearchMode(false)}>
							<Icon name='remove' />
						</div>
					)}
				</TransitionGroup>
			</div>
		</div>
		<TransitionGroup animation='fade up' duration={300}>
			{searchMode && (
				<div className={classNames("ar search-container", {
					results: searchQuery.length>=3 && !!searchResults
				})}>
					<div className="overlay" onClick={()=>setSearchMode(false)}></div>
					<div className="contents">
						<TransitionGroup animation='fade up' duration={300}>
							{searchQuery.length<3 ? <>
								<div className="left">
									<div className="title">{t('navbar.trends')}</div>
									<div className="trends">
										{webdata && webdata.trends ? (
											webdata.trends.map(a=>(
												<Trend text={a} onClick={onQuery} key={`trend-${a}`} />
											))
										) : <>
											<Trend placeholder />
											<Trend placeholder />
											<Trend placeholder />
											<Trend placeholder />
											<Trend placeholder />
										</>}
									</div>
								</div>
								<div className="right">
									<div className="title">{t('navbar.popular_events')}</div>
									{webdata && webdata.bestsellers ? webdata.bestsellers.map(a=>(
										<SearchEvent event={a} key={`bst-ppl-${a.event_id}-${a.legacy ? 1 : 0}`} />
									)) : [0, 1, 2].map(a=>(
										<SearchEvent placeholder key={`bst-plh-${a}`} />
									))}
								</div>
							</> : (searching || searchResults) ? (
								<div className="results">
									<div className="title">{t('navbar.results.title', { query: searchQuery })}</div>
									{searching ? (
										<div className="events">
											<div className="left">
												<SearchEvent placeholder />
												<SearchEvent placeholder />
												<SearchEvent placeholder />
											</div>
											<div className="right">
												<SearchEvent placeholder />
												<SearchEvent placeholder />
												<SearchEvent placeholder />
											</div>
										</div>
									) : (
										<div className="events">
											{!searchResults || searchResults.results.length==0 ? (
												<div className="empty">
													<Header iconName='face-frown-open' text={t('navbar.results.empty')} subtext={t('navbar.results.retry')} />
												</div>
											) : <>
												<div className="left">
													{!!results_left && results_left.map(a=>(
														<SearchEvent key={`SRCH-RST-${a.event_id}`} event={a} />
													))}
												</div>
												<div className="right">
													{!!results_right && results_right.map(a=>(
														<SearchEvent key={`SRCH-RST-${a.event_id}`} event={a} />
													))}
												</div>
											</>}
										</div>
									)}
								</div>
							) : (searchError && searchQuery.length>=3) ? <div style={{ width: '100%', marginTop: 15 }}>
								<Header text={t('error')} subtext={searchError} iconName='face-frown-open' />
								<Button iconName='arrow-left' text={t('return')} style={{ display: 'block', width: 200, margin: 'auto', marginTop: 15 }} onClick={()=>setSearchQuery('')} />
							</div> : null}
						</TransitionGroup>
					</div>
				</div>
			)}
		</TransitionGroup>
		{searchMode && <div className="ar search-overlay" onClick={()=>setSearchMode(false)}></div>}
		<TransitionGroup animation='fade right' duration={300}>
			{(sidebar && !searchMode) && (
				<div className="ar navsidebar">
					{NAVBAR_ITEMS.map((a, i)=>(<Link className="item" to={a.href} key={`sidebar-itm-${i}`}>{a.text}</Link>))}
				</div>
			)}
		</TransitionGroup>
		<TransitionGroup animation='fade' duration={200}>
			{(!!sidebar && !searchMode) && (
				<div className="ar closable" onClick={()=>setSidebar(false)}></div>
			)}
		</TransitionGroup>
		<Toaster position="bottom-right" toastOptions={{
			duration: 5000,
			error: {
				iconTheme: {
					primary: 'white',
					secondary: '#ff4b4b'
				},
				style: {
					color: 'white',
					background: '#ff4b4b',
				}
			},
			success: {
				iconTheme: {
					primary: 'white',
					secondary: 'rgb(89, 179, 67)'
				},
				style: {
					color: 'white',
					background: 'rgb(89, 179, 67)'
				}
			}
		}} />
	</>
}

export default Navbar;