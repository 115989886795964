import React, { useEffect } from 'react';
import { Groupper, Icon, IconName } from 'react-frontier';
import { useTitle } from '@arema/components/Hooks';
import classNames from 'classnames';

import '../style/nosotros.scss';
import { Trans, useTranslation } from 'react-i18next';

function IconSection(props: { icon: IconName, children: any, top?: boolean, title: string }){
	return <>
		<Groupper.Divider text={props.title} className={classNames({
			top: props.top
		})} />
		<div className='section'>
			<div className="left">
				<Icon name={props.icon} />
			</div>
			<div className="content">
				{props.children}
			</div>
		</div>
	</>
}

var Nosotros = ()=>{
	var { t } = useTranslation();
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle(t('about.title'));
	}, []);
	
	return <div className='ar nosotros-screen'>
		<Groupper title={t('about.title')} titleSize='big' titleCentered style={{ maxWidth: 600, marginTop: 15 }}>
			<IconSection title={t('about.title')} icon="ticket" top>
				{t('about.whatdo.text')}
				<ul>
					{(t('about.whatdo.list', { returnObjects: true }) as string[]).map((a,i)=>(
						<li key={`whatdo-${i}`}>{a}</li>
					))}
				</ul>
			</IconSection>
			<IconSection title={t('about.contact.title')} icon="address-book">
				{t('about.contact.text')}
				<Icon name="facebook" /> <a href="https://facebook.com/aremamx">facebook.com/aremamx</a>
				<br />
				<Icon name="envelope" /> <a href="mailto:hola@arema.mx">hola@arema.mx</a>
			</IconSection>
			<IconSection title={t('about.business.title')} icon="flag">
				<Trans i18nKey={'about.business.text'} />
			</IconSection>
			<IconSection title={t('about.business_location.title')} icon="building">
				{t('about.business_location.text')}
			</IconSection>
			<IconSection title={t('about.representatives.title')} icon="user">
				{t('about.representatives.text')}
			</IconSection>
			<IconSection title={t('about.join.title')} icon="handshake">
				{t('about.join.text')}
			</IconSection>
			<IconSection title={t('about.refunds.title')} icon={"hand-holding-usd"}>
				<Trans i18nKey={'about.refunds.text.0'}>
					<a href="/reembolsos"></a>
				</Trans>
				<br /> <br />
				{t('about.refunds.text.1')}
			</IconSection>
			<IconSection title={t('about.socials.title')} icon='globe'>
				<Icon name="facebook" /> <a href="https://facebook.com/aremamx">Facebook</a>
				<br />
				<Icon name="twitter" /> <a href="https://twitter.com/aremamx">Twitter</a>
				<br />
				<Icon name="instagram" /> <a href="https://instagram.com/aremamx">Instagram</a>
				<br />
				<Icon name="globe" /> <a href="https://tiktok.com/aremamx">TikTok</a>
			</IconSection>
		</Groupper>
	</div>
}

export default Nosotros;