import React, { useEffect, useState } from 'react';
import { PublicEventGroup as PublicEventGroupClass } from '@arema/components/Classes';
import { useParams } from 'react-router-dom';
import { useTitle } from '@arema/components/Hooks';
import { EventHeader, EventItem, NotFound } from '../components';
import { Header } from 'react-frontier';
import { CDN_URL } from '../PublicConfig';
import { cropString } from '@arema/components/Util';
import API from '../PublicAPI';
import moment from 'moment';

interface PublicEventGroupProps{
	group_id?: number,
	legacy?: boolean
}

var PublicEventGroup = (props: PublicEventGroupProps)=>{
	var { group: param_group } = useParams();
	var { setTitle } = useTitle();
	var [group, setGroup] = useState<PublicEventGroupClass>(null);
	var [notFound, setNotFound] = useState<boolean>(null);
	var [loadError, setLoadError] = useState<string>(null);

	var getGroupId = ()=>{
		var group_id : number = null
		if(group) return group.group_id;
		if(param_group && !Number.isNaN(parseInt(param_group))){
			group_id = parseInt(param_group)
		}else if(props.group_id && !Number.isNaN(parseInt(props.group_id.toString()))){
			group_id = parseInt(props.group_id.toString());
		}
		return group_id;
	}

	useEffect(()=>{
		var group_id = getGroupId();
		if(!group && group_id){
			setNotFound(false);
			var fn = props.legacy ? API.getLegacyGroup : API.getEventGroup;
			fn(group_id).then(res=>{
				if(res.error) return setLoadError(res.message);
				if(!res.data) return setNotFound(true);
				setGroup(res.data);
			}).catch(err=>{
				return setLoadError(`Hubo un error inesperado cargando el grupo. (PLCL-GRPGT-${props.legacy ? 'L' : ''}1)`);
			});
		}
	}, []);

	useEffect(()=>{
		if(group){
			setTitle(group.group_name);
		}
	}, [group]);

	var group_id = getGroupId();

	if(!group_id || notFound){
		return <NotFound />
	}
	if(loadError){
		return <Header
			containerStyle={{ marginTop: 20 }}
			text='Error'
			iconName='face-frown-open'
			subtext={loadError}
		/>
	}

	if(!group){
		return <div className="ar event-group">
			<EventHeader />
		</div>
	}
	
	return <div className="ar event-group">
		<EventHeader
			poster={group.poster || `${CDN_URL}/groups/${group.group_id}/400.webp`}
			data={{
				name: group.group_name,
				sinopsis: group.description,
			}}
		/>
		{group.events && group.events.length>0 ? (
			<div className="ar events">
				{group.events.map(a=>(
					<EventItem
						key={`EVT-${a.event_id}`}
						name={a.event_name}
						url={`/${a.subdomain ? '@' : `e/`}${a.subdomain || a.event_id}`}
						headerLeft={a.category_name}
						headerRight={moment.unix(a.date).format('DD/MMM')}
						poster={a.poster || `${CDN_URL}/events/${a.event_id}/400.webp`}
						meta={[
							cropString(a.venue_name || '', 32, true),
							cropString(`${a.city}${a.state && a.state.length>0 ? ', ' : ''}${a.state && a.state.length>0 ? a.state : ''}`, 32, true)
						]}
					/>
					
				))}
			</div>
		) : (
			<Header text='Sin eventos' subtext='No hay eventos diponibles para este grupo' />
		)}
	</div>
}

export default PublicEventGroup;